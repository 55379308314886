$red: #df533e;

.gradient {
	background: linear-gradient(
			to left,
			var( --wp--preset--color--background ),
			rgba( 0, 0, 0, 0 ),
			var( --wp--preset--color--background )
		),
		linear-gradient( to bottom, rgba( #de4b34, 0.4 ), var( --wp--preset--color--background ) );
}
